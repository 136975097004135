.activity-cell-action {
	display: inline-flex;
	gap: 8px;
	button,
	a {
		border: 0;
		padding: 0;
		background-color: transparent;
		outline: 0;
		display: inline-flex;
		align-items: center;
		gap: 4px;
		cursor: pointer;
		text-decoration: none;
		transition: all ease-in-out 0.3s;
		font-size: 13px;
		font-weight: 500;
		color: #9aa0aa;
		&:hover {
			color: #f5f5f5;
		}
	}
}
