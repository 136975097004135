.vote {
	border-radius: var(--mm-card-border-radius);
	background-color: var(--mm-card-bg);
	padding: 12px;
	font-size: 13px;
	line-height: 18px;
	font-weight: 500;
	min-height: 105px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-bottom: 12px;
	color: #f5f5f5;
	.values {
		display: flex;
		justify-content: space-between;
		min-height: 50px;
		align-items: end;
		font-size: 12px;
		.value {
			display: flex;
			gap: 6px;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			color: #9aa0aa;
			font-weight: 400;
			img {
				height: 24px;
				margin: 4px 0;
			}
		}
		.percent {
			width: 190px;
			height: 32px;
			margin-bottom: 24px;
			display: flex;
			align-items: center;
			gap: 4px;
			span {
				display: inline-flex;
				height: 6px;
				&.green {
					background-color: #88d693;
					border-radius: 8px 0 0 8px;
				}
				&.red {
					background-color: #f04866;
					border-radius: 0 8px 8px 0;
				}
			}
		}
	}
}
