.connect-tg-bot {
	border-radius: var(--mm-card-border-radius);
	background-color: var(--mm-card-bg);
	padding: 12px;
	display: flex;
	align-items: center;
	gap: 10px;
    margin-bottom: 12px;
    svg{
        width: 16px;
        min-width: 16px;
        height: auto;
    }
	span {
		font-size: 13px;
		font-weight: 500;
		color: #f5f5f5;
        white-space: nowrap;
	}
	a {
		font-size: 12px;
		font-weight: 500;
        white-space: nowrap;
        margin-left: auto;
	}
}
