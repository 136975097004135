.mm-meme-simp-card {
	display: flex;
	align-items: center;
	gap: 8px;
	a{
		text-decoration: none;
		color: #9aa0aa;
	}
	.marked{
		opacity: .5;
		margin-right: 5px;
		img{
			width: 16px;
		}
	}
	.image {
		position: relative;
		display: inline-flex;
		.avatar {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			width: 32px;
			height: 32px;
			border-radius: 50%;
			overflow: hidden;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.network {
			position: absolute;
			bottom: 0;
			right: 0;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			width: 12px;
			height: 12px;
			overflow: hidden;
			border-radius: 50%;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
	.info {
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 4px;
	}
	.top,
	.bottom {
		display: inline-flex;
		align-items: center;
		gap: 4px;
		line-height: 1;
		font-size: 12px;
		img {
			width: 12px;
			height: 12px;
		}
	}
	.top {
		gap: 6px;
		b {
			color: #fff;
			font-size: 14px;
		}
		button {
			background-color: transparent;
			border: 0;
			padding: 0;
			outline: 0;
			cursor: pointer;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			transition: all ease-in-out .3s;
			opacity: .5;
			&:hover{
				opacity: 1;
			}
		}
	}
	.bottom{
		a{
			transition: all ease-in-out .3s;
			color: #9aa0aa;
			&:hover{
				color: #fff;
			}
		}
	}
}
