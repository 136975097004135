.mm-footer {
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	z-index: 98;
	padding: 10px 24px 10px calc(var(--mm-sidebar-width) + 24px);
	height: var(-mm-footer-height);
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 24px;
	border-top: 1px solid rgba(255, 255, 255, 0.05);
	ul {
		display: inline-flex;
		align-items: center;
		list-style: none;
		padding: 0;
		margin: 0;
		gap: 20px;
		line-height: 1;
	}
}
