.mm-header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 98;
	height: var(--mm-header-height);
	padding: 8px 24px 8px calc(var(--mm-sidebar-width) + 24px);
	display: flex;
	align-items: center;
	gap: 20px;
	border-bottom: 1px solid rgba($color: #fff, $alpha: 0.05);
	ul {
		display: inline-flex;
		align-items: center;
		list-style: none;
		padding: 0;
		margin: 0;
		gap: 20px;
		li {
			a {
				font-size: 15px;
				font-weight: 500;
				text-decoration: none;
				color: #9aa0aa;
				transition: all ease-in-out 0.3s;
				line-height: 1;
				&.active,
				&:hover {
					color: #fff;
				}
			}
		}
	}
	.price{
		font-size: 13px;
		display: inline-flex;
		align-items: center;
		gap: 4px;
		color: #fff;
		b{
			color: rgb(42, 144, 42);
			font-weight: 500;
		}
	}
	.connect {
		border-radius: 30px;
		padding-left: 16px;
		padding-right: 16px;
		height: 32px;
		font-weight: 500;
	}
	.user {
		.dropdown-toggle {
			display: inline-flex;
			align-items: center;
			gap: 8px;
			background-color: transparent;
			background-color: #28292b;
			padding: 5px 10px 5px 6px;
			outline: 0;
			box-shadow: none;
			border-radius: 10px;
			&::after {
				transition: all ease-in-out 0.3s;
			}
			&.show {
				&::after {
					transform: rotate(180deg);
				}
			}
			cursor: pointer;
			.avatar {
				width: 26px;
				height: 26px;
				border-radius: 50%;
				overflow: hidden;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
			.info {
				font-size: 12px;
				line-height: 1;
				display: inline-flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 3px;
				margin-right: -4px;
				.balance {
					display: inline-flex;
					align-items: center;
					gap: 4px;
					font-weight: 500;
					color: #fff;
				}
				.address {
					display: inline-flex;
					align-items: center;
					gap: 6px;
					button {
						padding: 0;
						background-color: transparent;
						border: 0;
						outline: 0;
						cursor: pointer;
						display: inline-flex;
						align-items: center;
						justify-content: center;
						opacity: 0.5;
						transition: all ease-in-out 0.3s;
						&:hover {
							opacity: 1;
						}
					}
				}
			}
		}
		.dropdown-menu {
			margin-top: 5px;
			background-color: #28292b;
			border: 0;
			padding: 6px;
			.dropdown-item {
				font-size: 12px;
				font-weight: 500;
				border-radius: 6px;
				transition: all ease-in-out 0.3s;
				display: flex;
				align-items: center;
				gap: 10px;
				padding: 8px 10px 10px 10px;
				line-height: 1;
				color: #fff;
				&:first-child {
					border: 1px solid rgba($color: #fff, $alpha: 0.1);
					margin-bottom: 6px;
					padding: 6px 10px 7px 8px;
				}
				&:hover {
					background-color: #44464a;
				}
			}
		}
	}
}
