.mm-connect-popup {
	text-align: center;
	.modal-content {
		background-color: rgba($color: #1d1e20, $alpha: 0.6);
	}
	.modal-header {
		.btn-close {
			opacity: 1;
		}
	}
	.modal-body {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 30px;
		h1 {
			color: #fff;
			font-weight: bold;
		}
		.action {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 10px;
			.btn {
				display: inline-flex;
				align-items: center;
				gap: 8px;
				font-size: 14px;
				font-weight: 500;
				&:first-child {
					img {
						filter: invert(1);
					}
				}
				&:last-child {
					transition: all ease-in-out 0.3s;
					&:hover {
						color: #fff;
					}
				}
			}
		}
	}
}
