.mm-footer-link {
	display: inline-flex;
	gap: 4px;
	align-items: center;
	text-decoration: none;
	color: #9aa0aa;
	transition: all ease-in-out 0.3s;
	line-height: 1;
	&:hover {
		color: #fff;
		img {
			opacity: 1;
		}
	}
	&.sm {
		img {
			height: 16px;
		}
	}
	img {
		height: 20px;
		opacity: 0.6;
		transition: all ease-in-out 0.3s;
	}
	.count {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		font-size: 11px;
		font-weight: 500;
		border-radius: 99px;
		padding: 3px 4px 2px;
		background-color: #28292b;
		color: #fff;
		min-width: 20px;
		@media (max-width: 767px) {
			display: none;
		}
	}
	span {
		@media (max-width: 767px) {
			display: none;
		}
	}
}
