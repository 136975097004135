.pool-info {
	border-radius: var(--mm-card-border-radius);
	background-color: var(--mm-card-bg);
	padding: 12px;
	font-size: 12px;
	line-height: 18px;
	margin-bottom: 12px;
	color: #5c6068;
	&-header {
		color: #f5f5f5;
		font-size: 13px;
		font-weight: 500;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 12px;
	}
	.item {
		display: flex;
		align-items: center;
		gap: 10px;
		justify-content: space-between;
		&:not(:last-child) {
			margin-bottom: 12px;
		}
		span,
		a {
			display: inline-flex;
			align-items: center;
			&:last-child {
				font-weight: 500;
				color: #f5f5f5;
			}
			svg {
				margin-left: 4px;
			}
		}
		a,
		.clickable {
			cursor: pointer;
		}
	}
}
