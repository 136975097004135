.trending-panel {
	font-size: 12px;
	padding-top: 8px;
	border-radius: var(--mm-card-border-radius);
	background-color: var(--mm-card-bg);
	[class*='ag-theme-'] {
		--ag-font-size: 12px !important;
		--ag-range-selection-border-color: transparent;
		--ag-range-selection-background-color: transparent;
	}
	min-height: calc(100vh - 56px - 12px - 42px - 292px - 12px);
	overflow-y: auto;
	.nav-tabs {
		border: 0;
		margin: 0 6px 8px;
	}
	.nav-item {
		.nav-link {
			padding: 4px 6px;
			border: 0 !important;
			background-color: transparent !important;
			font-size: 12px;
			color: #9aa0aa;
			&.active {
				color: #fff;
			}
		}
	}
	.filters {
		display: flex;
		margin: 8px 12px;
		border-radius: 8px;
		overflow: hidden;
		border: 1px #26282c solid;
		button {
			background-color: transparent;
			border: 0;
			padding: 0;
			flex: 1;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			font-size: 12px;
			color: #5c6068;
			outline: 0;
			transition: all ease-in-out 0.3s;
			height: 28px;
			&:hover {
				color: #f5f5f5;
			}
			&.active {
				color: #f5f5f5;
				background-color: #26282c;
			}
			&:not(:last-child) {
				border-right: 1px #26282c solid;
			}
		}
	}
	.ag-theme-quartz .ag-cell {
		padding: 4px;
		&:first-of-type{
			padding-left: 8px;
		}
		&:last-of-type{
			padding-right: 8px;
		}
	}
	.ag-theme-quartz .ag-row {
		border-bottom: 0;
	}
}
