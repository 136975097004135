.activity-panel {
	&-header {
		display: flex;
		align-items: center;
		gap: 8px;
		margin-bottom: 12px;
		.tabs {
			display: flex;
			align-items: center;
			gap: 8px;
		}
		.form-switch {
			margin-left: auto;
			font-size: 12px;
			font-weight: 500;
			min-height: unset;
			color: #f5f5f5;
			margin-right: 4px;
		}
		a,
		button {
			text-decoration: none;
			height: 28px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			gap: 4px;
			outline: 0;
			cursor: pointer;
			color: #5c6068;
			padding: 10px 12px;
			border: 0;
			background-color: #17181b;
			border-radius: 8px;
			font-size: 12px;
			transition: all ease-in-out 0.3s;
			&:hover {
				color: #f5f5f5;
			}
			&.active {
				color: #f5f5f5;
				background-color: #393c43;
			}
		}
	}
	&-subtabs {
		display: flex;
		align-items: center;
		border-radius: 8px;
		overflow: hidden;
		border: 1px solid #26282c;
		margin-bottom: 8px;
		a,
		button {
			text-decoration: none;
			height: 28px;
			padding: 0 12px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			font-size: 12px;
			color: #5c6068;
			outline: 0;
			background-color: transparent;
			border: 0;
			transition: all ease-in-out 0.3s;
			&:not(:last-child) {
				border-right: 1px solid #26282c;
			}
			&:hover {
				color: #f5f5f5;
			}
			&.active {
				background-color: #26282c;
				color: #f5f5f5;
			}
		}
		.form-check {
			font-size: 12px;
			font-weight: 500;
			min-height: unset;
			color: #f5f5f5;
		}
		.paused {
			display: inline-flex;
			align-items: center;
			gap: 4px;
			padding: 4px;
			color: #ffd039;
			background-color: #ffd03933;
			border-radius: 4px;
            font-size: 12px;
			height: 18px;
            margin-left: 8px;
		}
	}
	.ag-cell{
		line-height: unset;
	}
}
