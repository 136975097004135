.buy-panel {
	border-radius: var(--mm-card-border-radius);
	background-color: var(--mm-card-bg);
	padding: 12px;
	margin-bottom: 12px;
	&-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 12px;
		margin-bottom: 12px;
	}
	&-address {
		flex: 1;
		display: flex;
		align-items: center;
		font-size: 12px;
		padding: 0 8px;
		background-color: var(--mm-card-input-bg);
		border-radius: 8px;
		gap: 4px;
		height: 36px;
		.avatar {
			width: 20px;
			height: 20px;
			min-width: 20px;
			border-radius: 50%;
			overflow: hidden;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.address {
			display: flex;
			align-items: center;
			color: #f5f5f5;
		}
		.balance {
			margin-left: auto;
			display: inline-flex;
			align-items: center;
			gap: 2px;
			color: #f5f5f5;
			svg {
				height: 12px;
				width: 12px;
			}
		}
	}
	&-add {
		width: 36px;
		height: 36px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		border-radius: 8px;
		background-color: #26282c;
		transition: all ease-in-out 0.3s;
		&:hover {
			opacity: 0.8;
		}
		svg {
			height: 20px;
			width: 20px;
		}
	}
	&-tabs {
		display: flex;
		align-items: center;
		border-radius: 6px;
		padding: 1px;
		background: #111111;
		button {
			width: calc(100% / 3);
			border: 0;
			padding: 0;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			gap: 4px;
			height: 32px;
			font-size: 12px;
			color: #5c6068;
			background: #111111;
			transition: all ease-in-out 0.3s;
			border-radius: 6px;
			outline: 0;
			svg {
				width: 12px;
				height: 12px;
				min-width: 12px;
			}
			&.auto {
				&:after {
					content: '';
					width: 6px;
					height: 6px;
					border-radius: 50%;
					background-color: #5c6068;
					margin-left: 4px;
				}
				svg {
					width: auto;
					height: 16px;
				}
			}
			&.active {
				&.buy {
					color: #111111;
					background-color: #88d693;
				}
				&.sell {
					color: #111111;
					background-color: #f04866;
				}
				&.auto {
					background-color: #26282c;
				}
			}
		}
	}
	&-tab-panel {
		.tabs {
			display: flex;
			align-items: center;
			gap: 12px;
			font-size: 12px;
			color: #5c6068;
			margin: 12px 0 8px;
			button {
				font-weight: 500;
				outline: 0;
				padding: 0;
				background-color: transparent;
				border: 0;
				color: #5c6068;
				cursor: pointer;
				transition: all ease-in-out 0.3s;
				&:hover,
				&.active {
					color: #f5f5f5;
				}
			}
			span {
				margin-left: auto;
				white-space: nowrap;
			}
		}
	}
}
.buy-sell-form {
	.input-wrap{
		margin-bottom: 12px;
		border-radius: 8px;
		border: 1px solid rgba($color: #ffc107, $alpha: 0.5);
	}
	.input-amount {
		position: relative;
		font-size: 12px;
		span {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			z-index: 1;
			color: #9aa0aa;
			&:first-child {
				left: 12px;
			}
			&:last-child {
				right: 12px;
			}
		}
		input {
			background-color: transparent;
			height: 36px;
			width: 100%;
			outline: 0;
			border: 1px solid var(--mm-card-input-border-color);
			border-radius: 8px 8px 0 0;
			color: #f5f5f5;
			font-size: 12px;
			font-weight: 500;
			padding-left: 72px;
			padding-right: 40px;
			line-height: 36px;
		}
	}
	.quick-input {
		border-top: 1px solid rgba($color: #ffc107, $alpha: 0.2);
		display: flex;
		border-radius: 0 0 8px 8px;
		overflow: hidden;
		button {
			background-color: transparent;
			border: 0;
			outline: 0;
			cursor: pointer;
			font-size: 12px;
			font-weight: 500;
			color: #5c6068;
			transition: all ease-in-out 0.3s;
			height: 26px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			flex: 1;
			&:not(:last-child) {
				border-right: 1px solid rgba($color: #ffc107, $alpha: 0.2);
			}
			&:hover {
				background: #26282c;
				color: #f5f5f5;
			}
		}
	}
	.take-profit-stop-loss {
		margin-bottom: 12px;
		.form-check {
			font-size: 12px;
			min-height: unset;
		}
	}
	.btn {
		height: 32px;
		font-size: 14px;
		font-weight: 500;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 12px;
		border-radius: 8px;
	}
	.accordion {
		font-size: 12px;
		border: 0;
		&-item {
			border: 0;
			background-color: transparent;
		}
		&-header {
			padding: 0;
		}
		&-button {
			display: flex;
			align-items: center;
			gap: 10px;
			padding: 0;
			border: 0;
			font-size: 12px;
			color: #5c6068;
			border: 0 !important;
			box-shadow: none !important;
			background-color: transparent !important;
			white-space: nowrap;
			&::after {
				width: 12px;
				height: 12px;
				filter: grayscale(1);
				background-size: cover;
			}
			u {
				color: rgba($color: #f5f5f5, $alpha: 0.7);
			}
		}
		&-body {
			padding: 20px 0 0;
			display: flex;
			flex-direction: column;
			gap: 12px;
			.item {
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 6px;
				&-label {
					flex: 1;
				}
				button,
				input:not([type="checkbox"]) {
					background-color: transparent;
					min-width: 78px;
					width: 78px;
					height: 30px;
					display: inline-flex;
					align-items: center;
					justify-content: center;
					border: 1px solid rgba(245, 245, 245, 0.1);
					border-radius: 0.375rem;
					outline: 0;
					transition: all ease-in-out 0.3s;
					cursor: pointer;
					padding: 0 8px;
					white-space: nowrap;
					&.active {
						border-color: #f5f5f583;
					}
				}
				.input-field {
					position: relative;
					input {
						padding-right: 20px;
					}
					span {
						color: #9aa0aa;
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						right: 12px;
						font-size: 12px;
					}
				}
			}
		}
	}
}
#tooltip-take-profit-stop-loss {
	.tooltip-inner {
		text-align: left;
		p {
			padding-left: 20px;
			b {
				margin-left: -20px;
			}
		}
	}
}
