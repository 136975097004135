.mm-sidebar {
	width: var(--mm-sidebar-width);
	height: 100vh;
	overflow: hidden;
	background-color: #141517;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99;
	.logo {
		display: flex;
		height: var(--mm-header-height);
		align-items: center;
		justify-content: center;
		outline: 0;
		text-decoration: none;
		border-bottom: 1px solid rgba($color: #fff, $alpha: 0.05);
		img {
			height: 28px;
		}
	}
	.menu {
		overflow-y: auto;
		list-style: none;
		padding: 20px 10px;
		margin: 0;
		max-height: calc(100% - var(--mm-header-height) - var(--mm-footer-height));
	}
	.version {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: var(--mm-footer-height);
		display: flex;
		align-items: center;
		justify-content: center;
		color: #9aa0aa;
		border-top: 1px solid rgba($color: #fff, $alpha: 0.03);
	}
}
