.mf-referral-cta-card {
    font-size: 12px;
    color: #9aa0aa;
	background: linear-gradient(
		160deg,
		rgba(109, 157, 116, 0.7) 0%,
		rgb(24, 28, 35) 50%,
		rgba(34, 67, 103, 0.7) 100%
	);
	padding: 16px;
	border-radius: 8px;
	h3 {
		color: #fff;
		font-size: 26px;
		line-height: 1.5;
		font-weight: bold;
		margin-bottom: 12px;
	}
	p {
		font-size: 14px;
		margin-bottom: 12px;
        color: #f5f5f5;
	}
	ul {
		margin-bottom: 12px;
        padding-left: 16px;
        li{
            &:not(:last-of-type){
                margin-bottom: 8px;
            }
        }
	}
    .btn{
        font-weight: 500;
        border-radius: 8px;
    }
    h5{
        font-size: 16px;
        font-weight: bold;
        color: #fff;
        margin-top: 20px;
    }
}
