.audit {
	background: #17181b;
	border-radius: 12px;
	padding: 12px;
	color: #f5f5f5;
	font-weight: 500;
	font-size: 13px;
	a {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 12px;
		font-size: 12px;
		font-weight: 500;
		text-decoration: none;
		color: #f5f5f5;
		img {
			height: 20px;
			margin-top: -0.1rem;
			margin-right: 0.25rem;
		}
	}
}
