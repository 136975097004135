.trending-token-item {
	display: flex;
	align-items: center;
	gap: 8px;
	.image {
		position: relative;
		img {
			width: 24px;
			height: 24px;
			border-radius: 50%;
		}
		.network {
			width: 10px;
			height: 10px;
			position: absolute;
			bottom: 5px;
			right: 0;
			z-index: 2;
		}
	}
	.info {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		.name {
			display: inline-flex;
			align-items: center;
			gap: 4px;
			font-size: 13px;
			line-height: 20px;
			font-weight: 500;
			color: #f5f5f5;
			a {
				transition: all ease-in-out 0.3s;
				color: #9aa0aa;
				&:hover {
					color: #fff;
				}
			}
		}
		.meta {
			color: #5c6068;
			font-size: 12px;
			line-height: 18px;
			display: inline-flex;
			align-items: center;
			gap: 2px;
		}
	}
}
