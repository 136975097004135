.mm-button-tabs {
	.mm-button-tab {
		font-size: 13px;
		padding-left: 12px;
		padding-right: 12px;
		border-color: rgba($color: #fff, $alpha: 0.1);
		height: 30px;
		display: inline-flex;
		align-items: center;
        justify-content: center;
		&:first-child {
			border-radius: 10px 0 0 10px;
		}
		&:last-child {
			border-radius: 0 10px 10px 0;
		}
		&:hover {
			background-color: transparent !important;
			color: #fff;
		}
		&.btn-secondary {
			background-color: #141517 !important;
		}
	}
}
