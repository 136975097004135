.trending-token-price{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    span{
        &:first-child{
            font-size: 13px;
            line-height: 20px;
            color: #f5f5f5;
        }
        &:last-child{
            font-size: 12px;
            line-height: 18px;
        }
    }
}