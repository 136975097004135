.mm-sidebar-item {
	&:not(:last-of-type) {
		margin-bottom: 10px;
	}
	a,
	button {
		width: 100%;
		padding: 0;
		border: 0;
		background-color: transparent;
		outline: 0;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 36px;
		text-decoration: none;
		font-weight: bold;
		border-radius: 5px;
		&.active {
			background-color: #28292b;
		}
		span {
			display: none;
		}
	}
	img {
		height: 22px;
	}
}
