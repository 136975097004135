.update-token {
	display: flex;
	height: 80px;
	padding: 16px;
	gap: 8px;
	flex-direction: column;
	cursor: pointer;
	border-radius: var(--mm-card-border-radius);
	background-color: var(--mm-card-bg);
	text-decoration: none;
	margin-bottom: 12px;
	font-size: 12px;
	color: #9aa0aa;
	transition: all ease-in-out 0.3s;
	&:hover {
		color: #f5f5f5;
	}
	&-header {
		font-size: 13px;
		font-weight: 500;
		color: #f5f5f5;
	}
	&-action {
		display: inline-flex;
		align-items: center;
		gap: 4px;
		svg {
			width: 16px;
			height: 16px;
		}
	}
}
