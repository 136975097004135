.quick-links {
	height: 32px;
	background-color: var(--mm-card-bg);
	border-radius: var(--mm-card-border-radius);
	padding: 6px 0;
	display: flex;
	align-items: center;
	justify-content: space-around;
	margin-bottom: 12px;
	font-size: 12px;
	svg {
		width: 12px;
		min-width: 12px;
		height: auto;
		.icon{
			width: 14px;
			height: 14px;
		}
		&.text{
			width: auto;
			height: 16px;
		}
	}
	.item {
		color: #f5f5f5;
		display: flex;
		align-items: center;
		gap: 4px;
		text-decoration: none;
		padding: 0 8px;
		&:not(:last-of-type) {
			border-right: 1px solid rgba(255, 255, 255, 0.16);
		}
	}
}
