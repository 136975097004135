.quick-view {
	border-radius: var(--mm-card-border-radius);
	background-color: var(--mm-card-bg);
	overflow: hidden;
	margin-bottom: 12px;
	.tabs {
		display: flex;
		border-bottom: 1px solid #26282c;
		button {
			width: 25%;
			height: 54px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			transition: all ease-in-out 0.3s;
			font-size: 12px;
			line-height: 18px;
			color: #9aa0aa;
			border: 0;
			background-color: transparent;
			outline: 0;
			padding: 0;
			span {
				&:last-child {
					font-weight: 500;
				}
			}
			&:hover {
				color: #f5f5f5;
			}
			&.active {
				color: #f5f5f5;
				background-color: var(--mm-card-button-active);
			}
			&:not(:last-of-type) {
				border-right: 1px solid #26282c;
			}
		}
	}
	.items {
		display: flex;
		justify-content: space-between;
		padding: 8px 12px;
		border-bottom: 1px solid #26282c;
		gap: 10px;
		flex-wrap: wrap;
		.item {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 4px;
			font-size: 12px;
			line-height: 18px;
			color: #f5f5f5;
			&:last-child {
				align-items: flex-end;
			}
			&.full {
				flex-direction: row;
				justify-content: space-between;
				width: 100%;
			}
			> span {
				&:first-child {
					color: #5c6068;
				}
				&:not(:first-child) {
					font-weight: 500;
					display: inline-flex;
					align-items: center;
					justify-content: center;
					gap: 4px;
					svg {
						width: 14px;
						min-width: 14px;
						height: 14px;
					}
				}
			}
		}
	}
}
