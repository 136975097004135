.mm-new-creation-card {
	display: flex;
	gap: 8px;
	align-items: center;
	&:not(:last-of-type) {
		padding-bottom: 12px;
		margin-bottom: 15px;
		border-bottom: 1px dashed rgba($color: #fff, $alpha: 0.08);
	}
	.image {
		width: 60px;
		height: 60px;
		border-radius: 50%;
		overflow: hidden;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		background: conic-gradient(
			rgb(136, 214, 147) 0%,
			rgba(136, 214, 147, 0.4) 0%
		);
		padding: 2px;
		span {
			display: flex;
			align-items: center;
			justify-content: center;
			width: calc(100% - 2px);
			height: calc(100% - 2px);
			overflow: hidden;
			border-radius: 50%;
			margin: 1px;
		}
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.info {
		flex: 1;
	}
	.top {
		display: flex;
		align-items: center;
		gap: 8px;
		font-size: 13px;
		margin-bottom: 4px;
		b {
			font-weight: 600;
			color: #fff;
		}
		span {
			font-size: 12px;
			max-width: 100px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		button {
			font-size: 12px;
			background-color: transparent;
			border: 0;
			outline: 0;
			cursor: pointer;
			padding: 0;
			display: inline-flex;
			align-items: center;
			gap: 4px;
			img {
				width: 12px;
				height: 12px;
				opacity: 0.5;
				transition: all ease-in-out 0.3s;
			}
			&:hover {
				img {
					opacity: 1;
				}
			}
			&:last-child {
				margin-left: auto;
				img {
					opacity: 1;
				}
			}
			&.buy {
				transition: all ease-in-out .3s;
				padding: 1px 4px 1px 3px;
				border-radius: 4px;
				&:hover {
					background-color: rgba($color: #fff, $alpha: 0.1);
				}
			}
		}
	}
	.mid {
		margin-bottom: 8px;
		font-size: 11px;
		line-height: 1;
		white-space: nowrap;
		display: flex;
		align-items: center;
		gap: 10px;
		&-item {
			display: inline-flex;
			align-items: center;
			gap: 3px;
		}
	}
	.bottom {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 10px;
		font-size: 11px;
		line-height: 1;
		white-space: nowrap;
		color: #9aa0aa;
		&-left,
		&-right {
			display: inline-flex;
			align-items: center;
			gap: 6px;
			a {
				text-decoration: none;
				svg {
					width: 14px;
					height: 14px;
				}
			}
			b {
				font-weight: 500;
				color: #fff;
			}
		}
		&-left {
			a {
				color: #9aa0aa;
				transition: all ease-in-out 0.3s;
				&:hover {
					color: #fff;
				}
			}
		}
		&-right {
			gap: 8px;
			&-item {
				display: inline-flex;
				align-items: center;
				gap: 3px;
			}
		}
	}
}
