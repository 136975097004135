.activity-cell-type {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: 13px;
	line-height: 24px;
	font-weight: 500;
	border-radius: 4px;
	padding: 0 10px;
	&.buy {
		background: rgba(136, 214, 147, 0.2);
		color: rgb(136, 214, 147);
	}
	&.sell {
		background: rgba(240, 72, 102, 0.2);
		color: rgb(240, 72, 102);
	}
}
