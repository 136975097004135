.mm-main {
	padding: 12px 12px 0px;
	overflow: hidden;
	margin-top: var(--mm-header-height);
	margin-left: var(--mm-sidebar-width);
	height: calc(100vh - var(--mm-header-height) - var(--mm-footer-height));
	.mm-card-list {
		display: block;
	}
	.card {
		border: 0;
		&.full {
			&.no-header {
				height: calc(100vh - 215px + 37.5px);
			}
			.card-body {
				height: calc(100vh - 215px);
				overflow-y: auto;
			}
		}
		&-header {
			padding: 5px 10px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			border: 0;
		}
		&-title {
			font-size: 14px;
			color: #fff;
			font-weight: 500;
			margin-bottom: 0;
		}
		.dropdown-toggle {
			box-shadow: none !important;
			outline: 0;
			display: inline-flex;
			align-items: center;
			font-size: 13px;
			gap: 4px;
			outline: 0 !important;
			border: 0 !important;
			img {
				opacity: 0.5;
				transition: all ease-in-out 0.3s;
			}
			&.show,
			&:hover {
				color: #fff;
				img {
					opacity: 1;
				}
			}
			&::after {
				display: none;
			}
		}
		.dropdown-menu {
			font-size: 12px;
		}
		&-body {
			background-color: var(--bs-card-cap-bg);
		}
	}
}
.mm-page-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 30px;
	margin-bottom: 16px;
	&-left,
	&-right {
		display: inline-flex;
		align-items: center;
		gap: 8px;
	}
	&-left {
		.btn-tab {
			font-weight: 600;
			border: 0 !important;
			img {
				height: 16px;
				opacity: 0.5;
				transition: all ease-in-out 0.3s;
			}
			&:hover,
			&.active {
				color: #fff;
				img {
					opacity: 1;
				}
			}
		}
		.btn-sm {
			height: 30px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			font-size: 13px;
			line-height: 1;
			border-radius: 10px;
			img {
				height: 12px;
			}
		}
	}
	&-right {
		font-size: 12px;
		color: #fff;
		> img {
			height: 16px;
			width: 16px;
			min-width: 16px;
		}
		.input-wrap {
			position: relative;
			width: 100px;
			img {
				position: absolute;
				top: 50%;
				left: 10px;
				transform: translateY(-50%);
			}
			.form-control {
				font-size: 14px;
				box-shadow: none;
				outline: 0;
				border-color: rgba($color: #fff, $alpha: 0.2);
				transition: all ease-in-out 0.3s;
				padding-left: 30px;
				border-radius: 10px;
				height: 30px;
				&::placeholder {
					opacity: 0.4;
					font-size: 13px;
					font-weight: 300;
				}
				&:focus {
					border-color: rgba($color: #ffc107, $alpha: 0.3);
				}
			}
		}
		button {
			height: 30px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			transition: all ease-in-out 0.3s;
			background-color: transparent;
			border: 0;
			outline: 0;
			cursor: pointer;
			padding: 0;
			&:hover {
				img {
					opacity: 1;
				}
			}
			img {
				opacity: 0.5;
				transition: all ease-in-out 0.3s;
			}
		}
	}
}
.token-detail {
	display: flex;
	gap: 12px;
	.left-panel {
		width: 265px;
		min-width: 265px;
	}
	.content {
		flex: 1;
	}
	.right-panel {
		width: 320px;
		min-width: 320px;
	}
	.left-panel,
	.right-panel,
	.content {
		max-height: calc(100vh - 56px - 42px - 12px - 12px);
		overflow-y: auto;
		-ms-overflow-style: none;
		scrollbar-width: none;
		&::-webkit-scrollbar {
			display: none;
		}
	}
}
