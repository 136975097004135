* {
	scrollbar-width: thin;
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import 'bootstrap/dist/css/bootstrap.min.css';
@import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the Data Grid
@import 'ag-grid-community/styles/ag-theme-quartz.css'; // Optional Theme applied to the Data Grid
:root {
	--mm-header-height: 56px;
	--mm-footer-height: 40px;
	--mm-sidebar-width: 56px;
	--mm-card-bg: #14151794;
	--mm-card-border-radius: 12px;
	--mm-card-button-active: #28292b;
	--mm-card-input-bg: #28292b;
	--mm-card-input-border-color: #14151780;
	--bs-body-font-size: 14px;
	--bs-body-font-family: Poppins, system-ui, -apple-system, 'Segoe UI', Roboto,
		'Helvetica Neue', 'Noto Sans', 'Liberation Sans', Arial, sans-serif,
		'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
[data-bs-theme='dark'] {
	--bs-body-bg: #1d1e20;
	--bs-body-bg-rgb: 29, 30, 32;
	--bs-body-color: #9aa0aa;
	--bs-body-color-rgb: 154, 160, 170;
}
.modal {
	.modal-content {
		padding: 0 16px;
		border-radius: 20px;
		border: 0;
		background-color: #1d1e20;
	}
	.modal-header {
		padding: 20px 16px;
		border: 0;
		.modal-title {
			font-size: 18px;
			color: #fff;
		}
		.btn-close {
			box-shadow: none;
			outline: 0;
		}
	}
	.modal-body {
		padding: 0 16px;
		overflow-y: auto;
		.form-label,
		.input-group-text {
			font-size: 13px;
		}
		.input-group-text {
			min-width: 90px;
		}
		.form-control {
			font-size: 14px;
			box-shadow: none;
			outline: 0;
			border-color: rgba($color: #fff, $alpha: 0.2);
			transition: all ease-in-out 0.3s;
			&::placeholder {
				opacity: 0.4;
				font-size: 13px;
				font-weight: 300;
			}
			&:focus {
				border-color: rgba($color: #ffc107, $alpha: 0.3);
			}
		}
		.form-text {
			opacity: 0.5;
		}
		.accordion-item {
			border: 0;
			margin-bottom: 0;
			.accordion-body {
				padding: 5px 0 0;
			}
			.accordion-button {
				padding: 5px 0;
				font-size: 14px;
				background-color: transparent !important;
				outline: 0;
				box-shadow: none;
				&:not(.collapsed) {
					color: #ffc107;
				}
				&:after {
					filter: grayscale(1);
				}
			}
		}
	}
	.modal-footer {
		border: 0;
		padding: 20px 16px 16px;
		* {
			margin: 0;
		}
		.btn {
			font-size: 16px !important;
			height: 45px;
			font-weight: 500;
			border-radius: 24px;
			margin-top: 16px;
			margin-bottom: 8px;
		}
		.form-text {
			font-size: 12px;
			color: #fff;
			width: 100%;
			text-align: left;
		}
	}
}
.mm-uploader {
	.file-types {
		display: none;
	}
	&:hover {
		label {
			border-color: rgba($color: #ffc107, $alpha: 0.3);
		}
		svg {
			path {
				fill: rgba($color: #ffc107, $alpha: 0.3);
			}
		}
		span {
			color: #fff;
		}
	}
	label {
		border-width: 2px;
		border-color: rgba($color: #fff, $alpha: 0.2);
		transition: all ease-in-out 0.3s;
	}
	svg {
		path {
			fill: rgba($color: #fff, $alpha: 0.2);
			transition: all ease-in-out 0.3s;
		}
	}
	span {
		transition: all ease-in-out 0.3s;
	}
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	/* display: none; <- Crashes Chrome on hover */
	-webkit-appearance: none;
	margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
	-moz-appearance: textfield; /* Firefox */
}

/* Modal */
.modal {
	.btn-close {
		filter: invert(1);
	}
}
.modal-backdrop {
	background-color: #02061e;
	backdrop-filter: blud(6px);
	&.show {
		opacity: 0.8;
	}
}
@media (min-width: 768px) {
	.modal-sm {
		--bs-modal-width: 400px;
	}
}

.block_ui_button {
	clip-path: polygon(
		0 0,
		calc(100% - 20px) 0,
		100% 20px,
		100% 100%,
		20px 100%,
		0 calc(100% - 20px)
	);
}

.block_ui_button .block-ui-overlay {
	background-color: #7766a2;
}
body{
	overflow: hidden;
}
body::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

*::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

*::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
	background-color: #12026a;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	/* display: none; <- Crashes Chrome on hover */
	-webkit-appearance: none;
	margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
	-moz-appearance: textfield; /* Firefox */
}
.terms-of-use {
	h3 {
		font-family: 'Friz Quadrata Std Bold', sans-serif;
		margin-bottom: 20px;
	}
	ol {
		padding: 16px 16px 16px 40px;
		border-radius: 10px;
		background-color: rgba($color: #000000, $alpha: 0.1);
		font-size: 16px;
		margin-bottom: 0;
	}
}
.form-control-wrap {
	position: relative;
	.form-control {
		border-radius: 3px;
		background-color: transparent !important;
		font-size: 20px;
		line-height: 1;
		text-transform: uppercase;
		color: #ffffff;
		padding: 10px 90px 10px 16px;
	}
	.form-select {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 4px;
		background-color: #041134;
		opacity: 0.32;
		border-radius: 0px 3px 3px 0px;
		font-size: 15px;
		line-height: 19px;
		color: #ffffff !important;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 30px;
		width: 90px;
		border: 0;
		padding-right: 20px;
		background-position: right 5px center;
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
	}
}
@media (min-width: 576px) {
	.modal-sm {
		--bs-modal-width: 400px;
	}
}
@media (min-width: 1200px) {
	.g-xxl-5,
	.gx-xxl-5 {
		--bs-gutter-x: 32px;
	}
	.g-xxl-5,
	.gy-xxl-5 {
		--bs-gutter-y: 32px;
	}
}
.btn-secondary {
	--bs-btn-bg: #28292b;
	--bs-btn-border-color: #28292b;
	--bs-btn-hover-bg: #36373a;
	--bs-btn-hover-border-color: #36373a;
}
.btn-outline-secondary {
	--bs-btn-color: #fff;
	--bs-btn-border-color: #36373a;
	--bs-btn-hover-bg: #36373a;
	--bs-btn-hover-border-color: #36373a;
	font-weight: 500;
}
.btn-group-sm > .btn,
.btn-sm {
	--bs-btn-font-size: 12px;
}
/* dark-theme.css */
.ag-theme-quartz {
	--ag-font-family: Poppins;
	--ag-background-color: transparent;
	--ag-header-background-color: transparent;
	--ag-header-foreground-color: #fff;
	--ag-body-background-color: transparent;
	--ag-body-foreground-color: #fff;
	--ag-row-hover-color: rgba(0, 0, 0, 0.1);
	--ag-border-color: transparent;
	--ag-borders-critical: rgba(255, 255, 255, 0.1);
	--ag-filter-background-color: #333;
	--ag-filter-input-border-color: #444;
	--ag-font-size: 14px;
	--ag-icon-size: 12px;
	--ag-foreground-color: #5c6068;
	.ag-row,
	.ag-header,
	.ag-advanced-filter-header {
		border-bottom: 1px solid var(--ag-borders-critical);
	}
	.ag-cell,
	.ag-full-width-row .ag-cell-wrapper.ag-row-group,
	.ag-header-cell,
	.ag-header-group-cell {
		padding: 8px;
	}
	.ag-header-cell-text {
		font-size: 12px;
		color: #5c6068;
		--ag-icon-font-color: #5c6068;
	}
	.ag-header-cell-sorted-desc,
	.ag-header-cell-sorted-asc {
		.ag-header-cell-text {
			color: #fff;
		}
		.ag-sort-indicator-container {
			--ag-icon-font-color: #fff;
		}
	}
	.ag-filter-active,
	.ag-column-menu-visible .ag-header-cell-filter-button {
		--ag-icon-font-color: #fff;
	}
	.ag-cell-label-container {
		justify-content: flex-start;
	}
	.ag-header-cell-filter-button {
		margin-right: auto;
		margin-left: 5px;
		& ~ .ag-header-cell-label {
			flex: unset;
		}
	}
}

.search {
	position: relative;
	border-radius: 99px;
	flex: 1;
	img {
		position: absolute;
		top: 50%;
		left: 10px;
		transform: translateY(-50%);
		opacity: 0.5;
	}
	.form-control {
		font-size: 13px;
		background-color: #28292b;
		border-radius: 30px;
		min-width: 260px;
		padding: 8px 8px 8px 36px;
		border: 0;
		outline: 0;
		box-shadow: none;
		line-height: 1;
		&::placeholder {
			opacity: 0.5;
		}
	}
}
.green {
	color: #afdfb6 !important;
}
.blue {
	color: #93c5fd !important;
}
.red {
	color: #f04866 !important;
}
.tooltip {
	--bs-tooltip-bg: #111;
	--bs-tooltip-color: #f5f5f5;
	--bs-tooltip-padding-x: 0.5rem;
	--bs-tooltip-padding-y: 0.25rem;
	--bs-tooltip-font-size: 12px;
	--bs-tooltip-border-radius: 0.375rem;
}
.form-check-input {
	box-shadow: none !important;
	&:focus {
		border-color: #ffc107;
	}
	&:checked {
		background-color: #ffc107;
		border-color: #ffc107;
	}
	&:checked[type='checkbox'] {
		--bs-form-check-bg-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23000%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e');
	}
}
