.auto-panel {
	padding: 32px 12px 12px;
	background-color: #111111;
	border-radius: 8px;
	.top {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 28px;
		img {
			height: 42px;
		}
		.icon {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			gap: 6px;
			span {
				display: inline-flex;
				width: 8px;
				height: 8px;
				border-radius: 50%;
				background-color: #393c43;
			}
		}
	}
	p {
		margin: 20px 0;
		text-align: center;
		font-size: 14px;
		strong {
			color: #88d693;
		}
	}
	.btn {
		font-size: 14px;
		font-weight: 500;
	}
}
