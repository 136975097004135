.mf-referral-chart-card {
	padding: 0;
	position: relative;
	background-color: transparent;
	.card-body {
		background-color: transparent;
		padding: 0;
		height: calc(100vh - 180px);
		overflow: hidden;
		display: flex;
		align-items: flex-end;
	}
	.info {
		position: absolute;
		top: 30px;
		left: 0;
		z-index: 3;
		color: #fff;
		h3 {
			font-size: 24px;
			line-height: 1.5;
			font-weight: 500;
		}
		.value {
			display: inline-flex;
			align-items: center;
			gap: 10px;
			font-size: 24px;
			font-weight: 500;
			span {
				font-size: 48px;
				font-weight: 700;
				background: linear-gradient(
						101deg,
						#ffc720 9.97%,
						rgb(198, 124, 21) 60%
					)
					text;
				color: transparent;
			}
		}
	}
	.pop {
		width: 140px;
		position: absolute;
		z-index: 4;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-size: 12px;
		transition: all ease-in-out 0.3s;
		transform: translateY(-100%);
		line-height: 1.3;
		&.pop-0 {
			bottom: 20%;
			left: 12.2%;
		}
		&.pop-1 {
			bottom: 31%;
			left: 33%;
		}
		&.pop-2 {
			bottom: 45%;
			left: 53.5%;
		}
		&.pop-3 {
			bottom: 66%;
			left: 74.5%;
		}
		&::after {
			width: 1px;
			height: 45px;
			content: '';
			position: absolute;
			top: calc(100% + 5px);
			left: 50%;
			transform: translateX(-50%);
			background-color: #9aa0aa;
		}
		b {
			display: inline-flex;
			align-items: center;
			font-weight: 500;
			font-size: 16px;
			span {
				color: #ffc107;
				font-weight: 600;
				font-size: 24px;
			}
		}
	}
	.echarts-for-react {
		width: 100% !important;
		height: 90% !important;
	}
}
