.button.btn {
    position: relative;
    padding: 0;
    border-radius: 12px;
    border: 0;
    >span,
    >img {
        position: relative;
        z-index: 2;
    }
    >span {
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        background-size: cover;
        background-position: top center;
        padding: 10px 80px;
        border-radius: 12px;
        font-family: 'Friz Quadrata Std Bold', sans-serif;
        font-size: 30px;
        @media (max-width: 1199px) {
            font-size: 25px;
            padding: 10px 40px;
        }
    }
    .effect {
        position: absolute;
        bottom: -18px;
        left: 0;
        // animation: btn-effect 3s infinite;
        transition: opacity ease-in-out 0.1s, left ease-in-out .4s;
        opacity: 0;
        transform-origin: center;
        transform: scale(0.8);
    }
    &.btn-sm {
        >span {
            font-size: 20px;
            padding: 8px 20px;
        }
        .effect {
            height: 35px;
            bottom: -15px;
        }
        &::before {
            top: 4px;
            left: 4px;
        }
        &::after {
            top: 2px;
            left: 2px;
        }
        &:hover {
            &::before {
                top: 8px;
                left: 8px;
            }
            &::after {
                top: 4px;
                left: 4px;
            }
            .effect{
                left: 40%;
            }
        }
    }
    &::before,
    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 12px;
        transition: all ease-in-out 0.3s;
        z-index: 1;
    }
    &::before {
        opacity: 0.37;
        top: 7px;
        left: 7px;
    }
    &::after {
        top: 3.5px;
        left: 3.5px;
    }
    &:hover {
        .effect{
            left: 65%;
            opacity: 1;
            transform: scale(1);
        }
        &::before {
            top: 14px;
            left: 14px;
            opacity: 0.1;
        }
        &::after {
            top: 7px;
            left: 7px;
            opacity: 0.5;
        }
    }
    &.btn-primary {
        &::before {
            background-color: #075dc8;
        }
        &::after {
            background-color: #131692;
        }
        >span {
            background-image: url(../../assets/images/btn-primary-bg.png);
        }
    }
    &.btn-warning {
        color: #912421;
        &::before {
            background-color: #5c3e34;
        }
        &::after {
            background-color: #9d4b59;
        }
        >span {
            background-image: url(../../assets/images/btn-warning-bg.png);
        }
    }
    &.btn-success {
        color: #0b3170;
        &::before {
            background-color: #0a551d;
        }
        &::after {
            background-color: #047d23;
        }
        >span {
            background-image: url(../../assets/images/btn-success-bg.png);
        }
    }
    &.btn-danger {
        &::before {
            background-color: #550a0a;
        }
        &::after {
            background-color: #a30404;
        }
        >span {
            background-image: url(../../assets/images/btn-danger-bg.png);
        }
    }
    &.btn-secondary {
        &:hover {
            >span {
                color: #04264c;
                background-color: #00fffc;
            }
        }
        &::before {
            display: none;
        }
        &::after {
            background-color: #04264c;
            border-radius: 8px;
        }
        >span {
            background-color: #032974;
            transition: all ease-in-out 0.3s;
        }
    }
    @keyframes btn-effect {
        0% {
            left: 0px;
            opacity: 0;
        }
        10%,
        90% {
            left: 65%;
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
}