.activity-cell-maker {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	.top {
		display: flex;
		align-items: center;
		gap: 4px;
		font-size: 14px;
		font-weight: 500;
		color: #f5f5f5;
		a,
		button {
			padding: 0;
			outline: 0;
			cursor: pointer;
			background-color: transparent;
			border: 0;
			display: inline-flex;
			align-items: center;
			justify-content: center;
		}
		.chip {
			min-width: 16px;
			height: 18px;
			padding-inline: 4px;
			border-radius: 4px;
			background: #393c43;
			color: #9aa0aa;
			font-size: 12px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
		}
		svg {
			width: 14px;
			height: 14px;
			min-width: 14px;
		}
	}
	.status {
		width: 100px;
		height: 4px;
		border-radius: 22px;
		background-color: #393c43;
		overflow: hidden;
		position: relative;
		.percent {
			position: absolute;
			top: 0;
			left: 0;
			background-color: #9aa0aa;
			height: 100%;
			transition: all ease-in-out 0.3s;
		}
	}
}
