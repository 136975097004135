.watch-panel {
	font-size: 12px;
	padding-top: 8px;
	border-radius: var(--mm-card-border-radius);
	background-color: var(--mm-card-bg);
	[class*='ag-theme-'] {
		--ag-font-size: 12px !important;
	}
	min-height: 280px;
	margin-bottom: 12px;
	.nav-tabs {
		border: 0;
		margin: 0 6px 8px;
	}
	.nav-item {
		.nav-link {
			padding: 4px 6px;
			border: 0 !important;
			background-color: transparent !important;
			font-size: 12px;
			color: #9aa0aa;
			&.active {
				color: #fff;
			}
		}
	}
	.tab-search {
		padding: 0 12px;
		margin-bottom: -8px;
		.form-control {
			min-height: 28px;
			height: 28px;
			max-width: 100%;
			min-width: unset;
		}
	}
	.tab-check {
		margin-bottom: -10px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 12px;
		padding: 0 12px;
	}
	.table-wrap {
		height: 215px;
	}
}
