.section-title {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    > h3 {
        font-family: 'Friz Quadrata Std Bold', sans-serif;
        font-size: 32px;
        text-transform: uppercase;
        color: #fff;
        text-shadow: 1px 1px 10px #0876ff, 1px 1px 10px #0876ff;
        margin-bottom: 0;
        @media (max-width: 375px) {
            font-size: 28px;
        }
    }
    .divider{
        height: 20px;
    }
    &.sm{
        >h3{
            font-size: 28px;
        }
    }
    &.divider-inline {
        > h3 {
            display: flex;
            align-items: center;
            white-space: nowrap;
            gap: 20px;
        }
    }
    &.center {
        > h3 {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            gap: 38px;
            white-space: nowrap;
            max-width: 100%;
            overflow: hidden;
        }
        > span {
            white-space: nowrap;
            @media (max-width: 767px) {
                white-space: unset;
            }
        }
    }
}
